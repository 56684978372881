<template>
    <div>
        
        <a-modal
        
        v-model="modals"
        @ok="handleOk"
        :footer="null"
        width:="640px"
        :centered="true"
        
        >
          <div slot="title">
            Plan {{ plan }}
          </div>
          
          <div class="plan-content">

            <a-collapse accordion v-if="data.length > 0" expand-icon-position="right">
                    <a-collapse-panel v-for="(item,i) in data" :key="i" :header="item.produk_detail">
                        <div class="detailproduk" v-if="item.documents.length > 0">
                            <ul>
                                <li v-for="(items,j) in item.documents" :key="j">
                                    <a-icon type="check-circle" theme="twoTone" two-tone-color="#52c41a" /><span>{{ items.produk_value }}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-else>
                            <a-empty />
                        </div>
                    </a-collapse-panel>
            </a-collapse>

            <a-empty v-else />
          </div>
        </a-modal>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    beforeCreate(){
        this.form = this.$form.createForm(this,{name:'FormAction'})
    },
    data() {
        return {
            modals: false,
            plan:'',
            isLoading: false,
            data:[]
        }
    },
      mounted() {
          //document.body.classList.add("ModalCustom");
    },
    destroyed() {
        //document.body.classList.remove("ModalCustom");
    },
    methods: {
        moment,
      
   
        handleOk() {
            this.modals = false
        },
        handleCancel() {
            this.modals = true            
        },
       
        Show(item) {
          
            this.modals = true
          
            this.plan = item

            this.$store.dispatch('GetPlan', {
                plan:item
            }).then(response => {
                this.data = response.data.data
            })
            // this.form.resetFields()
            
        },
        
    }
}
</script>

<style>
.ModalCustom .ant-modal-content{
    padding:2px 5px !important;
   
   
}

.ModalCustom  .ant-modal-header{
 border-radius: 24px !important;

 }

 .ModalCustom .ant-modal-close-x{
    margin-right: 20px;
 }

</style>
