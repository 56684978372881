<template>
  <div>
    <router-view>
      <template slot="onlybreadcrumb">
        <div>
          <div>
            <h1>{{ data.group }}</h1>
          </div>
          <slot name="onlybreadcrumb"></slot>
        </div>
      </template>
      <template slot="titlebreadcrumb">
        <div>
          <div>
            <h1>{{ data.group }}</h1>
            <div class="iconhealth">
              <img :src="require('@/assets/img/icon/health.svg')" />
              {{ data.jenis_asuransi }}
            </div>
          </div>
          <slot name="onlybreadcrumb"></slot>
        </div>
      </template>

      <template #titlePesertaBreadcrumb="{ messageProps }">
        <div>
          <div>
            <h1>{{ messageProps }}</h1>
          </div>
          <slot name="onlybreadcrumb"></slot>
        </div>
      </template>

      <template slot="benefitheader">
        <div class="titleheadright">
          <div class="_thr" v-if="$route.query.nama_perusahaan != undefined">
            <label>Perusahaan</label>
            <div>
              <p>{{ $route.query.nama_perusahaan }}</p>
            </div>
          </div>
          <div class="_thr">
            <label>Periode Polis</label>
            <div>
              <img :src="require('@/assets/img/icon/calendar.svg')" />
              <p>{{ `${data.periode_awal} - ${data.periode_akhir}` }}</p>
            </div>
          </div>
          <div class="_thr">
            <label>Asuransi</label>
            <div>
              <p>{{ data.asuransi }}</p>
            </div>
          </div>
          <div class="_thr">
            <label>Benefit</label>
            <div>
              <div>
                <ul>
                  <li
                    v-for="(item, i) in JSON.parse(data.benefit_existing)"
                    :key="i"
                    @click="Information(item)"
                  >
                    <img
                      :src="
                        require(`@/assets/img/icon/${item
                          .replace(/\s/g, '')
                          .toLowerCase()}.svg`)
                      "
                    />
                    {{ item }}
                  </li>
                  <!-- <li><img :src="require('@/assets/img/icon/ri.svg')" /> RI</li>
                  <li><img :src="require('@/assets/img/icon/rj.svg')" /> RJ</li>
                  <li><img :src="require('@/assets/img/icon/km.svg')" /> KC</li>
                  <li><img :src="require('@/assets/img/icon/rg.svg')" /> RG</li>
                  <li><img :src="require('@/assets/img/icon/rb.svg')" /> RB</li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template slot="menuall">
        <div class="allmenusub">
          <ul>
            <li>
              <router-link
                :to="{ name: 'ListDaftarPT' }"
                :class="$route.meta.title == 'Daftar PT' ? 'menusubactive' : ''"
                >Daftar PT</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'ListDataPeserta' }"
                :class="
                  $route.meta.title == 'Data Peserta' ? 'menusubactive' : ''
                "
                >Data Peserta</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'ListBenefit', query: { plan: 'RI' } }"
                :class="$route.meta.title == 'Benefit' ? 'menusubactive' : ''"
                >Benefit</router-link
              >
            </li>
            <li v-if="ban.indexOf($route.params.id) == -1">
              <router-link
                :to="{ name: 'ListKetentuanUmum' }"
                :class="
                  $route.meta.title == 'Ketentuan Umum' ? 'menusubactive' : ''
                "
                >Ketentuan Umum</router-link
              >
            </li>
          </ul>
        </div>
      </template>

      <template slot="menudatapeserta">
        <ul class="menutopdatapeserta">
          <li>
            <router-link
              :to="{
                name: 'ListDataPeserta',
                query: {
                  ...($route.query.id_perusahaan == undefined
                    ? null
                    : {
                        id_perusahaan: $route.query.id_perusahaan,
                        nama_perusahaan: $route.query.nama_perusahaan,
                      }),
                },
              }"
              :class="
                $route.meta.title == 'Data Peserta' && $route.query.menu == null
                  ? '_activemenutoppeserta'
                  : ''
              "
              :disabled="loadpeserta"
              >Aktif</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'ListDataPeserta',
                query: {
                  menu: 'nonaktif',
                  ...($route.query.id_perusahaan == undefined
                    ? null
                    : {
                        id_perusahaan: $route.query.id_perusahaan,
                        nama_perusahaan: $route.query.nama_perusahaan,
                      }),
                },
              }"
              :class="
                $route.meta.title == 'Data Peserta' &&
                $route.query.menu == 'nonaktif'
                  ? '_activemenutoppeserta'
                  : ''
              "
              :disabled="loadpeserta"
              >Non Aktif</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'ListDataPeserta',
                query: {
                  menu: 'coming-movement',
                  ...($route.query.id_perusahaan == undefined
                    ? null
                    : {
                        id_perusahaan: $route.query.id_perusahaan,
                        nama_perusahaan: $route.query.nama_perusahaan,
                      }),
                },
              }"
              :class="
                $route.meta.title == 'Data Peserta' &&
                $route.query.menu == 'coming-movement'
                  ? '_activemenutoppeserta'
                  : ''
              "
              :disabled="loadpeserta"
              >Coming Movement</router-link
            >
          </li>
        </ul>
      </template>
    </router-view>
    <Plan ref="plan" />
  </div>
</template>

<script>
import Plan from "@/components/Modal/plan.vue";
export default {
  components: {
    Plan,
  },
  data() {
    return {
      data: {},
      loading: false,
      ban: [
        "01b5bceb-3ea4-4332-9d96-f6d8a27c879d",
        "02c4c31b-9b6f-452e-9b02-fb77f56e9439",
        "02f52fd1-79f5-47ae-9e38-9aa0f4c85370",
        "065b62f0-8ef7-4180-bd63-6a4224844cda",
        "1561dbbb-af29-4be5-bc16-a487436d70a1",
        "2c1e3538-415e-4d0b-b9e3-91fcaddffc93",
        "2cbe7e18-16dd-4123-b193-101292b91348",
        "307ee3ed-e778-48be-be7f-ae0f27c6552f",
        "33b8107d-269c-4c49-a497-94cd26c62e9c",
        "3e5e59ad-f180-4dec-953c-698758cba790",
        "3ea6026b-85ba-4825-86b5-2b6a880a4b74",
        "405f3d97-32ed-4047-b210-7960448a6fd5",
        "4bff8f55-e959-4b92-a2d4-e33bb7fb2b87",
        "4f31913c-28d7-4b63-854c-9e36ba17a315",
        "56e9093d-47ef-4697-9a98-7a4d1207579a",
        "5afe56dd-c87c-48cc-b385-303fc9a6c7cd",
        "642b262c-0d32-4fee-8694-7f173a9dd631",
        "6663d56d-bd34-4356-9f53-bfbf1af7ca85",
        "69e2c50c-9b16-4f7c-be16-665b93c71f02",
        "6de9119e-4096-4e73-9026-9b8fdb4127d4",
        "6f744d60-ae3d-4174-8d8b-e450ee798b5d",
        "742d0adf-1576-41c4-9ff6-1ffcf2503746",
        "780e4d68-2aea-4068-b9fd-5ad05644690f",
        "7f7b0d5f-38b9-41a2-a45a-d0f318dbedcc",
        "8375cb63-d020-4655-9406-96e347dabb17",
        "867ef58b-ec1f-42a8-b208-ba4213c3e23b",
        "9d274590-0a7f-40a1-87b6-333e6824d394",
        "9d3b8acf-15f3-4116-ba2b-6819523dd74b",
        "9da2e2d2-38a5-4c2a-bb8c-d411ea18e8db",
        "a18a2d13-d4a9-4433-805b-415dcd14c7eb",
        "a54f7d22-433e-4035-bee2-74ceb96463df",
        "a9dda5be-19f2-40dd-bc5a-60af3d9eb640",
        "aeeef35c-4b90-431e-b1c3-853f4c44f06c",
        "b19f7efb-d821-454f-b5d9-8b4d400d8e81",
        "b41d9684-4bdd-46a6-93db-4aed466db55a",
        "b58a3408-2743-4307-9a59-f411de200f5c",
        "b6336cfc-689f-458a-8f2c-ce3719e38f73",
        "b7b90ff7-775b-4c6a-ba39-956740aafcb2",
        "b7c5400b-06ad-4a70-b4c9-db4fe6b4e52e",
        "c2397b62-c602-4c9d-a1c2-36fa83d9646b",
        "c3882a7d-2aef-4b37-a47c-d0088ff64719",
        "c63916d9-47a9-4399-af0f-927d21d6b5bf",
        "d864284d-592e-4994-935d-6697376e49f1",
        "df2deb80-0064-4cb9-adfb-97d013b9d7b4",
        "e103c459-2eea-4fde-b500-f613ea4d7c0f",
        "e441290a-1954-4c6f-8726-644bb564ede1",
        "e6c6c00f-7ca5-421a-8c3e-fa2cfc8df48a",
        "ece47834-6352-4fed-b3bd-107335a26516",
        "f13a8177-4aab-4d34-b630-3b764bccae76",
        "f59b1751-d713-4a3f-a7f5-3900b1ca5080",
        "f5e804aa-f1f3-4946-be9b-86361a1fb56e",
      ],
    };
  },
  computed: {
    loadpeserta() {
      return this.$store.state.loadpeserta;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    Information(e) {
      //alert("tes");
      this.$refs.plan.Show(e);
    },
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("getDetailGroup", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.loading = false;
          this.data = response.data.data;
        });
    },
  },
};
</script>
